import { store } from 'react-notifications-component';

const NOTIFICATION_DURATION = 4000;
const NOTIFICATION_WIDTH = 350;

const dispatchNotification = (title, message, type = 'default') =>
  store.addNotification({
    title,
    message,
    type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    width: NOTIFICATION_WIDTH,
    dismiss: {
      duration: NOTIFICATION_DURATION,
      pauseOnHover: true,
    },
    slidingEnter: {
      duration: 300,
      timingFunction: 'linear',
      delay: 0,
    },

    slidingExit: {
      duration: 300,
      timingFunction: 'linear',
      delay: 0,
    },
  });

export default dispatchNotification;
