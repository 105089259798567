import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Libraries
import { connect } from 'react-redux';
import { List, Avatar, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { PlusCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import { bindActionCreators } from 'redux';

import { userRolesConstants } from '../../constants/userRoles';

// Actions
import { getCalendars, editCalendar, createCalendar, deleteCalendar } from '../../actions/calendarActions';

// Components
import ModalCalendar from './calendarModal';

import './homePage.sass';
import dispatchNotification from '../../components/notification';

const emptyModalData = {
  name: '',
  description: '',
  announcement: '',
  earlyAccess: null,
  slotCap: null,
  slots: [],
  price: null,
  reader: '',
  billingAccount: '',
  cancellationPeriodLimit: null,
  active: false,
};

class HomePage extends PureComponent {
  state = {
    showModal: false,
    modalData: null,
    submitDataLoading: false,
    isModalEdit: null,
    listLoading: true,
  };

  componentDidMount() {
    if (this.props.calendarList.length) this.setState({ listLoading: false });
    this.props.getCalendars().then(() => this.setState({ listLoading: false }));
  }

  openEditModal = data => {
    this.setState({
      showModal: true,
      modalData: data,
      isModalEdit: true,
    });
  };

  openCreateModal = () => this.setState({ modalData: emptyModalData, showModal: true, isModalEdit: false });

  handleModalSave = data => {
    this.setState({ submitDataLoading: true });

    this.props
      .editCalendar(data)
      .then(() => this.setState({ showModal: false, modalData: null, submitDataLoading: false }))
      .catch(err => dispatchNotification('Error', err.response.data.message, 'danger'));
  };

  handleModalCreate = data => {
    this.setState({ submitDataLoading: true });

    this.props
      .createCalendar(data)
      .then(() => this.setState({ showModal: false, modalData: null, isModalEdit: null, submitDataLoading: false }))
      .catch(err => dispatchNotification('Error', err.response.data.message, 'danger'));
  };

  handleModalClose = () => this.setState({ showModal: false, modalData: null });

  deleteCalendar = item => this.props.deleteCalendar(item.id);

  render() {
    const { user, calendarList } = this.props;
    const { showModal, submitDataLoading, modalData, isModalEdit, listLoading } = this.state;

    if (user.role !== userRolesConstants.admin) {
      return calendarList ? (
        <div>
          <List
            className="home-page-calendar__wrapper"
            itemLayout="horizontal"
            dataSource={calendarList}
            loading={listLoading}
            renderItem={item => (
              <List.Item
                actions={[
                  <Link to={`/calendar/${item.id}`} key="list-edit-calendar">
                    Boka
                  </Link>,
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar icon={<CalendarOutlined />} />}
                  title={
                    <Link to={`/calendar/${item.id}`} key="list-edit-calendar">
                      {item.name}
                    </Link>
                  }
                  description={item.description}
                />
              </List.Item>
            )}
          />
        </div>
      ) : (
        <div>No Calendars</div>
      );
    }
    return (
      <div className="home-page-calendar">
        {modalData && (
          <ModalCalendar
            handleModalClose={this.handleModalClose}
            handleModalSave={this.handleModalSave}
            handleModalCreate={this.handleModalCreate}
            data={modalData}
            showModal={showModal}
            submitDataLoading={submitDataLoading}
            isModalEdit={isModalEdit}
          />
        )}
        <div className="home-page-calendar__create-wrapper">
          <div className="home-page-calendar__create" onClick={this.openCreateModal}>
            <PlusCircleOutlined />
            <span>Skapa</span>
          </div>
        </div>
        <List
          className="home-page-calendar__wrapper"
          itemLayout="horizontal"
          dataSource={calendarList}
          loading={listLoading}
          renderItem={item => (
            <List.Item
              actions={[
                <a key={`calendar-edit-action-${item.id}`} onClick={() => this.openEditModal(item)}>
                  Editera
                </a>,
                <Link to={`/calendar/${item.id}`} key={`list-show-calendar-${item.id}`}>
                  Boka
                </Link>,
                <Popconfirm
                  key={`calendar-DELETE-action-${item.id}`}
                  placement="top"
                  title="Ta bort"
                  onConfirm={() => this.deleteCalendar(item)}
                  okText="Ta bort"
                  cancelText="Tillbaka till huvudmenyn"
                >
                  <a style={{ color: '#ff4949' }}>Ta bort</a>
                </Popconfirm>,
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar icon={<CalendarOutlined />} />}
                title={<a>{item.name}</a>}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  calendarList: state.calendar.calendars,
});

const mapDispatchToProps = dispatch => ({
  getCalendars: bindActionCreators(getCalendars, dispatch),
  editCalendar: bindActionCreators(editCalendar, dispatch),
  createCalendar: bindActionCreators(createCalendar, dispatch),
  deleteCalendar: bindActionCreators(deleteCalendar, dispatch),
});

HomePage.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
  getCalendars: PropTypes.func,
  editCalendar: PropTypes.func,
  createCalendar: PropTypes.func,
  deleteCalendar: PropTypes.func,
  calendarList: PropTypes.arrayOf(PropTypes.shape({})),
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
