const routesPath = {
  HOME: '/',
  CALENDAR: '/calendar/:id',
  INVOICES: '/invoices',
  USERS: '/users',
  UNAUTHORIZED: '/unauthorized',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset',
};

export const HOME = 'Kalendrar';
export const INVOICES = 'Fakturor';
export const USERS = 'Användare';

export const AuthRoutes = {
  home: '/',
  invoices: '/invoices',
  users: '/users',
};

export const NonAuthRoutes = {
  login: '/login',
  unauthorized: '/unauthorized',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset',
};

export default routesPath;
