// AUTH
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_LOGIN_FROM_SESSION = 'SET_LOGIN_FROM_SESSION';
export const GET_USER = 'GET_USER';

// NAV BAR
export const TOGGLE_BAR = 'TOGGLE_BAR';
export const SET_GLOBAL_LOADING = 'SET_GLOBAL_LOADING';

// CALENDAR
export const GET_CALENDARS = 'GET_CALENDARS';
export const EDIT_CALENDARS = 'EDIT_CALENDARS';
export const CREATE_CALENDARS = 'CREATE_CALENDARS';
export const GET_SINGLE_CALENDAR = 'GET_SINGLE_CALENDAR';
export const BOOK_SLOT = 'BOOK_SLOT';
export const UN_BOOK_SLOT = 'UN_BOOK_SLOT';
export const DELETE_CALENDAR = 'DELETE_CALENDAR';

// USERS
export const GET_USERS = 'GET_USERS';
export const CREATE_USER = 'CREATE_USER';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';

// INVOICES
export const GET_INVOICES = 'GET_INVOICES';
export const DOWNLOAD_INVOICES = 'DOWNLOAD_INVOICES';
