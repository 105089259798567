import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
// import { NonAuthRoutes } from '../../constants/routes';

const NonAuthRoute = ({ Component, path, exact = false }) => {
  return <Route exact={exact} path={path} render={props => <Component {...props} />} />;
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

NonAuthRoute.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  path: PropTypes.string,
  exact: PropTypes.bool,
  requiredRoles: PropTypes.array,
};

export default connect(mapStateToProps)(NonAuthRoute);
