import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toggleBar } from '../../actions/navBarActions';
import { apiLogout } from '../../actions/authActions';

import Loader from '../loader';
import Logo from '../../assets/logo/Logo_blue_3_low.png';

import './navBarTop.sass';

import routesPath, { HOME } from '../../constants/routes';
import { PoweroffOutlined } from '@ant-design/icons';

import { userRolesConstants } from '../../constants/userRoles';

class NavBarTop extends Component {
  getPageName = () => {
    const array = this.props.location.pathname.split('/');
    const location = `/${array[1]}`;
    switch (location) {
      case routesPath.HOME:
        return HOME;
      default:
        return '';
    }
  };

  logout = () => {
    this.props.logout && this.props.logout();
  };
  render() {
    const {
      toggleBar,
      navBar: { expanded, globalLoading },
      user,
    } = this.props;
    return (
      <div className="nav-bar-top">
        {user.role === userRolesConstants.admin ? (
          <div className={`nav-bar-top__tog${expanded ? ' nav-bar-top__tog--expand' : ''}`}>
            <div role="button" tabIndex={0} className="nav-bar-top__tog_icon" onClick={toggleBar}>
              <Loader loading={globalLoading} className={`icon-dots${expanded ? ' icon-dots--rotate' : ''}`} />
            </div>
          </div>
        ) : null}
        <div className="nav-bar-top__main">
          {/*<h2>{this.getPageName()}</h2>*/}
          <img style={{ width: 200, marginLeft: 20 }} src={Logo} alt="Logo" />
          <div className="nav-bar-top__avatar">
            <div className="nav-bar-top__avatar_name">
              <span>{user.username}</span>
            </div>
            <div className="nav-bar-top__avatar_logout" onClick={this.logout}>
              <span>Logga ut</span>
              <PoweroffOutlined className="nav-bar-left__item_power-off" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  navBar: state.navBar,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  toggleBar: bindActionCreators(toggleBar, dispatch),
  logout: bindActionCreators(apiLogout, dispatch),
});

NavBarTop.propTypes = {
  navBar: PropTypes.shape({
    expanded: PropTypes.bool,
    globalLoading: PropTypes.bool,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  toggleBar: PropTypes.func,
  logout: PropTypes.func,
  user: PropTypes.shape({
    role: PropTypes.string,
    username: PropTypes.string,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBarTop));
