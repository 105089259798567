import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Input, Modal, Select } from 'antd';

const groupsIds = [
  { group_id: '0121' },
  { group_id: '0122' },
  { group_id: '0123' },
  { group_id: '0124' },
  { group_id: '0131' },
  { group_id: '0132' },
  { group_id: '0133' },
  { group_id: '0134' },
  { group_id: '0141' },
  { group_id: '0142' },
  { group_id: '0143' },
  { group_id: '0144' },
  { group_id: '0152' },
  { group_id: '0153' },
  { group_id: '0221' },
  { group_id: '0222' },
  { group_id: '0223' },
  { group_id: '0224' },
  { group_id: '0231' },
  { group_id: '0232' },
  { group_id: '0233' },
  { group_id: '0234' },
  { group_id: '0241' },
  { group_id: '0242' },
  { group_id: '0243' },
  { group_id: '0244' },
  { group_id: '0252' },
  { group_id: '0253' },
  { group_id: '0321' },
  { group_id: '0322' },
  { group_id: '0323' },
  { group_id: '0324' },
  { group_id: '0331' },
  { group_id: '0332' },
  { group_id: '0333' },
  { group_id: '0334' },
  { group_id: '0341' },
  { group_id: '0342' },
  { group_id: '0343' },
  { group_id: '0344' },
  { group_id: '0352' },
  { group_id: '0353' },
  { group_id: '0421' },
  { group_id: '0422' },
  { group_id: '0423' },
  { group_id: '0424' },
  { group_id: '0431' },
  { group_id: '0432' },
  { group_id: '0433' },
  { group_id: '0434' },
  { group_id: '0441' },
  { group_id: '0442' },
  { group_id: '0443' },
  { group_id: '0444' },
  { group_id: '0452' },
  { group_id: '0453' },
  { group_id: '0521' },
  { group_id: '0522' },
  { group_id: '0531' },
  { group_id: '0532' },
  { group_id: '0533' },
  { group_id: '0541' },
  { group_id: '0542' },
  { group_id: '0543' },
  { group_id: '0551' },
  { group_id: '0552' },
  { group_id: '0553' },
  { group_id: '0561' },
  { group_id: '0563' },
  { group_id: '0622' },
  { group_id: '0632' },
  { group_id: '0641' },
  { group_id: '0642' },
  { group_id: '0651' },
  { group_id: '0652' },
  { group_id: '0661' },
  { group_id: '0662' },
  { group_id: '0721' },
  { group_id: '0731' },
  { group_id: '0741' },
  { group_id: '0742' },
  { group_id: '0751' },
  { group_id: '0752' },
  { group_id: '0761' },
  { group_id: '0762' },
  { group_id: '0821' },
  { group_id: '0822' },
  { group_id: '0831' },
  { group_id: '0832' },
  { group_id: '0841' },
  { group_id: '0842' },
  { group_id: '0851' },
  { group_id: '0852' },
  { group_id: '0861' },
  { group_id: '0862' },
  { group_id: '0922' },
  { group_id: '0931' },
  { group_id: '0932' },
  { group_id: '0933' },
  { group_id: '0941' },
  { group_id: '0942' },
  { group_id: '0943' },
  { group_id: '0951' },
  { group_id: '0952' },
  { group_id: '0953' },
  { group_id: '0961' },
  { group_id: '0963' },
  { group_id: '1021' },
  { group_id: '1022' },
  { group_id: '1023' },
  { group_id: '1031' },
  { group_id: '1032' },
  { group_id: '1033' },
  { group_id: '1041' },
  { group_id: '1042' },
  { group_id: '1043' },
  { group_id: '1051' },
  { group_id: '1052' },
  { group_id: '1053' },
  { group_id: '1061' },
  { group_id: '1063' },
  { group_id: '1121' },
  { group_id: '1122' },
  { group_id: '1123' },
  { group_id: '1131' },
  { group_id: '1132' },
  { group_id: '1133' },
  { group_id: '1141' },
  { group_id: '1142' },
  { group_id: '1143' },
  { group_id: '1151' },
  { group_id: '1152' },
  { group_id: '1153' },
  { group_id: '1161' },
  { group_id: '1163' },
  { group_id: '1221' },
  { group_id: '1222' },
  { group_id: '1223' },
  { group_id: '1231' },
  { group_id: '1232' },
  { group_id: '1233' },
  { group_id: '1241' },
  { group_id: '1242' },
  { group_id: '1243' },
  { group_id: '1251' },
  { group_id: '1252' },
  { group_id: '1253' },
  { group_id: '1261' },
  { group_id: '1263' },
  { group_id: '1321' },
  { group_id: '1322' },
  { group_id: '1331' },
  { group_id: '1332' },
  { group_id: '1333' },
  { group_id: '1341' },
  { group_id: '1342' },
  { group_id: '1343' },
  { group_id: '1351' },
  { group_id: '1352' },
  { group_id: '1353' },
  { group_id: '1361' },
  { group_id: '1363' },
  { group_id: '9910' },
];

const UserCalendar = ({
  data,
  showModal,
  submitDataLoading,
  handleModalClose,
  handleModalSave,
  isModalEdit,
  handleModalCreate,
}) => {
  const [modalData, setModalData] = useState(data);

  const formOnChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setModalData({ ...modalData, [name]: value });
  };

  const roleOnChange = role => setModalData({ ...modalData, role });

  const groupIdOnChange = groupId => setModalData({ ...modalData, groupId });

  return (
    <Modal
      width={700}
      visible={showModal}
      title="Title"
      style={{ top: 20 }}
      onCancel={handleModalClose}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={handleModalClose}>
          Stäng
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={submitDataLoading}
          onClick={() => (isModalEdit ? handleModalSave(modalData) : handleModalCreate(modalData))}
        >
          Spara
        </Button>,
      ]}
    >
      <Form onChange={formOnChange} labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} layout="horizontal">
        <Form.Item labelCol={{ span: 5 }} label="Namn">
          <Input placeholder="Namn" name="name" value={modalData.name} />
        </Form.Item>
        <Form.Item labelCol={{ span: 5 }} label="Användarnamn">
          <Input
            autoComplete="off"
            placeholder="Användarnamn eller e-postadress"
            name="username"
            value={modalData.username}
          />
        </Form.Item>
        <Form.Item labelCol={{ span: 5 }} label="Lösenord">
          <Input autoComplete="off" placeholder="Lösenord" name="password" type="password" value={modalData.password} />
        </Form.Item>
        <Form.Item labelCol={{ span: 5 }} label="Repetera lösenord">
          <Input
            autoComplete="off"
            placeholder="Repetera lösenord"
            name="repeatPassword"
            type="password"
            value={modalData.repeatPassword}
          />
        </Form.Item>
        <Form.Item labelCol={{ span: 5 }} label="Roll">
          <Select placeholder="Role" value={modalData.role} onChange={roleOnChange}>
            <Select.Option value="ROLE_ADMIN">Admin</Select.Option>
            <Select.Option value="ROLE_USER">User</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item labelCol={{ span: 5 }} label="Lägenhet">
          <Select showSearch placeholder="Lägenhet" value={modalData.groupId} onChange={groupIdOnChange}>
            {groupsIds.map(item => (
              <Select.Option key={item.group_id} value={item.group_id}>
                {item.group_id}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

UserCalendar.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    username: PropTypes.string,
    password: PropTypes.string,
    role: PropTypes.string,
    groupId: PropTypes.string,
  }),
  showModal: PropTypes.bool,
  submitDataLoading: PropTypes.bool,
  handleModalSave: PropTypes.func,
  handleModalClose: PropTypes.func,
  formOnChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  handleModalCreate: PropTypes.func,
  isModalEdit: PropTypes.bool,
};

export default UserCalendar;
