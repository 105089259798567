import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Libraries
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { getResetLink } from '../../actions/authActions';

// Components
import { Input, Button } from 'antd';
import history from '../../utils/history';

import './forgotPasswordPage.sass';
import dispatchNotification from '../../components/notification';

const ForgotPasswordPage = ({ getResetLink }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState({ email: null, newPassword: null, repeatPassword: null, general: null });

  const { general: generalError, newPassword: newPasswordError, password: repeatPasswordError } = error;

  const onChange = e => {
    const {
      target: { id, value },
    } = e;
    if (id === 'email') setEmail(value);
  };

  const clearComponent = () => {
    setEmail('');
    setError({ newPassword: null, repeatPassword: null, general: null });
  };

  useEffect(() => {
    return () => clearComponent();
  }, []);

  const signIn = e => {
    e.preventDefault();
    history.push('/login');
  };

  const resetOnClick = () => {
    getResetLink(email).then(() => dispatchNotification('Lyckad', 'Bekräfta din e-postadress', 'success'));
  };

  return (
    <div className="forgot-password-page">
      <div className="forgot-password-page__wrapper">
        <h2>Ange din e-postadress</h2>
        {generalError && (
          <div className="login-page__general-error">
            {generalError && <div>{generalError}</div>}
            {newPasswordError && <div>{newPasswordError}</div>}
            {repeatPasswordError && <div>{repeatPasswordError}</div>}
          </div>
        )}
        <div>
          <Input value={email} id="email" type="email" onChange={onChange} error={error.email} />
          <div className="forgot-password-page__buttons-wrapper">
            <Button onClick={resetOnClick}>Återställ</Button>
            <a onClick={signIn} href="">
              Logga in
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  getResetLink: bindActionCreators(getResetLink, dispatch),
});

ForgotPasswordPage.propTypes = {
  getResetLink: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(ForgotPasswordPage);
