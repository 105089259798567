import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Libraries
import { connect } from 'react-redux';
import { PlusCircleOutlined } from '@ant-design/icons';
import { List, Popconfirm, Avatar } from 'antd';
import ModalCalendar from './userModal';

import { getUsers, deleteUser, editUser, createUser } from '../../actions/usersActions';

const emptyModalData = {
  name: '',
  username: '',
  password: '',
  role: undefined,
  groupId: undefined,
};

import './usersPage.sass';
import { bindActionCreators } from 'redux';
import { userRolesConstants } from '../../constants/userRoles';
import dispatchNotification from '../../components/notification';

class UsersPage extends PureComponent {
  state = {
    showModal: false,
    modalData: null,
    submitDataLoading: false,
    isModalEdit: null,
    listLoading: true,
  };

  componentDidMount() {
    if (this.props.users && this.props.users.length) this.setState({ listLoading: false });
    this.props.getUsers().then(() => this.setState({ listLoading: false }));
  }

  openEditModal = data => {
    this.setState({
      showModal: true,
      modalData: data,
      isModalEdit: true,
    });
  };

  openCreateModal = () => this.setState({ modalData: emptyModalData, showModal: true, isModalEdit: false });

  handleModalSave = data => {
    if (data.password === data.repeatPassword) {
      this.setState({ submitDataLoading: true });
      this.props
        .editUser(data)
        .then(() => this.setState({ showModal: false, modalData: null, submitDataLoading: false }))
        .catch(() => this.setState({ submitDataLoading: false }));
    } else {
      dispatchNotification('Lösenordsfel', 'Lösenord matchar inte', 'danger');
    }
  };

  handleModalCreate = data => {
    if (data.password === data.repeatPassword) {
      this.setState({ submitDataLoading: true });
      this.props
        .createUser(data)
        .then(() => this.setState({ showModal: false, modalData: null, isModalEdit: null, submitDataLoading: false }))
        .catch(err => {
          dispatchNotification('Problem vid skapande av användare', err.response.data.message, 'danger');
          this.setState({ submitDataLoading: false });
        });
    } else {
      dispatchNotification('Lösenordsfel', 'Lösenord matchar inte', 'danger');
    }
  };

  handleModalClose = () => this.setState({ showModal: false, modalData: null });

  deleteUser = id => this.props.deleteUser(id);

  render() {
    const { modalData, showModal, isModalEdit, submitDataLoading, listLoading } = this.state;
    return (
      <div className="user-page-calendar">
        {modalData && (
          <ModalCalendar
            handleModalClose={this.handleModalClose}
            handleModalSave={this.handleModalSave}
            handleModalCreate={this.handleModalCreate}
            data={modalData}
            showModal={showModal}
            submitDataLoading={submitDataLoading}
            isModalEdit={isModalEdit}
          />
        )}
        <div className="home-page-calendar__create-wrapper">
          <div className="home-page-calendar__create" onClick={this.openCreateModal}>
            <PlusCircleOutlined />
            <span>Create</span>
          </div>
        </div>
        <List
          className="home-page-calendar__wrapper"
          itemLayout="horizontal"
          dataSource={this.props.users ? this.props.users : []}
          loading={listLoading}
          renderItem={item => (
            <List.Item
              actions={[
                <a key={`calendar-edit-action-${item.id}`} onClick={() => this.openEditModal(item)}>
                  Editera
                </a>,
                <Popconfirm
                  key={`calendar-edit-action-${item.id}`}
                  placement="top"
                  title="Ta bort"
                  onConfirm={() => this.deleteUser(item.id)}
                  okText="Ta bort"
                  cancelText="Tillbaka till huvudmenyn"
                >
                  <a style={{ color: '#ff4949' }} key={`calendar-edit-action-${item.id}`}>
                    Ta bort
                  </a>
                </Popconfirm>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar style={{ backgroundColor: item.role === userRolesConstants.admin ? '#1abc9c' : '#c5c5c5' }}>
                    {item.role === userRolesConstants.admin ? 'ADMIN' : 'USER'}
                  </Avatar>
                }
                title={
                  <a onClick={() => this.openEditModal(item)}>
                    <span style={{ fontWeight: 'bold' }}>{item.name}</span> - Ap.Nr. {item.groupId}
                  </a>
                }
                // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
              />
            </List.Item>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  users: state.users,
});

const mapDispatchToProps = dispatch => ({
  getUsers: bindActionCreators(getUsers, dispatch),
  createUser: bindActionCreators(createUser, dispatch),
  editUser: bindActionCreators(editUser, dispatch),
  deleteUser: bindActionCreators(deleteUser, dispatch),
});

UsersPage.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
  }),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string,
      role: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  getUsers: PropTypes.func,
  createUser: PropTypes.func,
  deleteUser: PropTypes.func,
  editUser: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
