import React, { PureComponent } from 'react';
import { List } from 'antd';
import { bindActionCreators } from 'redux';
// Libraries
import { connect } from 'react-redux';
import { getInvoices, downloadInvoices } from '../../actions/invoicesActions';
import PropTypes from 'prop-types';

class InvoicesPage extends PureComponent {
  state = {
    listLoading: true,
  };

  componentDidMount() {
    this.props.getInvoices().then(() => this.setState({ listLoading: false }));
  }

  render() {
    const { invoices } = this.props;
    const { listLoading } = this.state;
    return (
      <div className="invoice-page">
        <List
          className="home-page-calendar__wrapper"
          itemLayout="horizontal"
          dataSource={invoices ? invoices : []}
          loading={listLoading}
          renderItem={item => (
            <List.Item
              actions={[
                <a key={`calendar-edit-action-${item.name}`} href={item.url}>
                  download
                </a>,
              ]}
            >
              <List.Item.Meta title={<a>{item.name}</a>} />
            </List.Item>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  invoices: state.invoices,
});

const mapDispatchToProps = dispatch => ({
  getInvoices: bindActionCreators(getInvoices, dispatch),
  downloadInvoices: bindActionCreators(downloadInvoices, dispatch),
});

InvoicesPage.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape({})),
  getInvoices: PropTypes.func,
  downloadInvoices: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesPage);
