import { TOGGLE_BAR, SET_GLOBAL_LOADING } from '../constants/actionTypes';

const initialState = {
  expanded: false,
  globalLoading: false,
};

export default function navBarReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_BAR:
      return {
        ...state,
        expanded: !state.expanded,
      };
    case SET_GLOBAL_LOADING:
      return {
        ...state,
        globalLoading: action.payload,
      };
    default:
      return state;
  }
}
