import { GET_INVOICES } from '../constants/actionTypes';

export default function invoicesReducer(state = null, action) {
  switch (action.type) {
    case GET_INVOICES:
      return action.payload;
    default:
      return state;
  }
}
