import { GET_INVOICES, DOWNLOAD_INVOICES } from '../constants/actionTypes';
import api from '../utils/api';

export const getInvoices = () => dispatch =>
  new Promise((resolve, reject) =>
    api.invoices
      .getInvoices()
      .then(res => {
        dispatch({
          type: GET_INVOICES,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );

export const downloadInvoices = fileName => dispatch =>
  new Promise((resolve, reject) =>
    api.invoices
      .downloadInvoice(fileName)
      .then(res => {
        dispatch({
          type: DOWNLOAD_INVOICES,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );
