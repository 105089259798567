import { GET_CALENDARS, EDIT_CALENDARS, CREATE_CALENDARS, DELETE_CALENDAR } from '../constants/actionTypes';

const initialState = {
  calendars: [],
  singleCalendar: null,
};

export default function calendarReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CALENDARS:
      return {
        ...state,
        calendars: action.payload,
      };
    case EDIT_CALENDARS: {
      const newList = [...state.calendars];
      const index = newList.findIndex(item => item.id === action.payload.id);
      newList[index] = action.payload;
      return {
        ...state,
        calendars: newList,
      };
    }
    case CREATE_CALENDARS: {
      return {
        ...state,
        calendars: [...state.calendars, { ...action.payload }],
      };
    }
    case DELETE_CALENDAR: {
      const newList = [...state.calendars];
      const index = newList.findIndex(item => item.id === action.payload);
      newList.splice(index, 1);
      return {
        ...state,
        calendars: newList,
      };
    }
    default:
      return state;
  }
}
