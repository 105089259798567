import React from 'react';
import PropTypes from 'prop-types';

import NavBarTop from './components/navBarTop';

const AuthenticatedApp = props => (
  <div className="app">
    <NavBarTop />
    <>{props.children}</>
  </div>
);

AuthenticatedApp.propTypes = {
  children: PropTypes.shape({}),
};

export default AuthenticatedApp;
