import React, { useEffect, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactNotification from 'react-notifications-component';

import { logout, setLoginFromSession, getUser } from './actions/authActions';

import Loader from './components/loader';
import UnauthenticatedApp from './unauthenticatedApp';
import AuthenticatedApp from './authenticatedApp';
// import history from './utils/history';

import './app.sass';

const App = props => {
  useEffect(() => {
    const token = window.localStorage.getItem('token');

    if (!token) {
      // history.push('/');
      props.logout && props.logout();
    } else {
      props.getUser && props.getUser().then(() => props.setLoginFromSession && props.setLoginFromSession(token));
    }
  }, []);

  if (props.auth.login === null) {
    return (
      <div className="suspense-loading-wrapper">
        <Loader loading={true} />
      </div>
    );
  }

  const {
    children,
    auth: { login, user },
  } = props;

  return (
    <Suspense
      fallback={
        <div className="suspense-loading-wrapper">
          <Loader loading />
        </div>
      }
    >
      <ReactNotification />
      {login !== null && login !== false && user !== null ? (
        <AuthenticatedApp>{children}</AuthenticatedApp>
      ) : (
        <UnauthenticatedApp />
      )}
    </Suspense>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  setLoginFromSession: bindActionCreators(setLoginFromSession, dispatch),
  logout: bindActionCreators(logout, dispatch),
  getUser: bindActionCreators(getUser, dispatch),
});

App.propTypes = {
  auth: PropTypes.shape({
    login: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    user: PropTypes.shape({}),
  }),
  children: PropTypes.shape({}),
  logout: PropTypes.func,
  getUser: PropTypes.func,
  setLoginFromSession: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
