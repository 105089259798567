import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import moment from 'moment';

import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { bindActionCreators } from 'redux';
import { LeftCircleOutlined } from '@ant-design/icons';
import { getSingleCalendar, bookSlot, unBookSlot, getCalendars } from '../../actions/calendarActions';
import dispatchNotification from '../../components/notification';
import { userRolesConstants } from '../../constants/userRoles';
import history from '../../utils/history';

import './calendarPage.sass';

const CalendarPage = props => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const [data, setData] = useState(null);
  const [currentRange, setCurrentRange] = useState(null);

  useEffect(() => {
    if (!props.calendarList.length) props.getCalendars();
  }, []);

  const events =
    data &&
    data.timeSlots.map(event => {
      const momentFormat = 'YYYY-MM-DD HH:mm:ss';

      return {
        id: event.id,
        title: event.groupId ? 'Lägenhet: ' + event.groupId : '',
        start: moment(event.start, momentFormat).format(momentFormat),
        end: moment(event.end, momentFormat).format(momentFormat),
        editable: false,
        color: event.booked ? (event.bookedByUser ? '#abd250' : '#cdcdcd') : '#3788d8',
        extendedProps: {
          ...event,
        },
      };
    });

  const getDateRange = ({ view }) => {
    return {
      start: moment(view.currentStart, 'LLLL').subtract(1, 'day').format('Y-M-D'),
      end: moment(view.currentEnd, 'LLLL')
        .subtract(1, 'day')
        .format('Y-M-D'),
    };
  };

  const viewOnChange = e => {
    const dateRange = getDateRange(e);
    const calendarId = id;

    if (currentRange === null) {
      props.getSingleCalendar(calendarId, dateRange.start, dateRange.end).then(res => {
        setData(res);
        setCurrentRange(getDateRange(e));
      });
    } else if (currentRange.start !== getDateRange(e).start) {
      props.getSingleCalendar(calendarId, dateRange.start, dateRange.end).then(res => {
        setData(res);
        setCurrentRange(getDateRange(e));
      });
    }
  };

  const eventOnClick = info => {
    const eventProps = info.event.extendedProps;
    const currentTimeBeforeEvent = moment(eventProps.start, 'YYYY-MM-DD HH:mm').diff(
      moment().format('YYYY-MM-DD HH:mm'),
      'minute',
    );
    const cancellationPeriodLimit = getObjectParameter('cancellationPeriodLimit');
    const isCancellationPeriodOk =
      (cancellationPeriodLimit && parseInt(cancellationPeriodLimit, 10) * 60 < currentTimeBeforeEvent) ||
      ((cancellationPeriodLimit === null || cancellationPeriodLimit === 0) && currentTimeBeforeEvent > 60);

    if (!eventProps.booked) {
      if (
        confirm(
          isCancellationPeriodOk
            ? 'Är du säker på att du vill boka detta?'
            : currentTimeBeforeEvent < 0
            ? 'Denna händelse är klar!'
            : `Vänligen säkerställ att du verkligen vill genomföra bokningen. Efter bokning tillåts inte avbokning  ${
                cancellationPeriodLimit ? `${cancellationPeriodLimit} timmar` : '0 timmar'
              } före bokningens starttid.`,
        )
      ) {
        const formData = new FormData();
        formData.append('timeSlotId', info.event.id);
        props
          .bookSlot(formData)
          .then(res => {
            const newData = [...data.timeSlots];
            const index = newData.findIndex(data => data.id === info.event.id);
            newData[index] = {
              ...newData[index],
              booked: true,
              bookedByUser: true,
              groupId: res.booking.groupId,
            };

            setData({ ...data, timeSlots: newData });
          })
          .catch(err => {
            dispatchNotification(
              'Bokningsfel',
              err.response && err.response.data && err.response.data.message,
              'danger',
            );
          });
      }
    } else if (eventProps.booked && eventProps.bookedByUser && props.user.role === userRolesConstants.user) {
      if (confirm('Är du säker på att du vill avboka tiden?')) {
        props
          .unBookSlot(info.event.id)
          .then(() => {
            const newData = [...data.timeSlots];
            const index = newData.findIndex(data => data.id === info.event.id);
            newData[index] = {
              ...newData[index],
              booked: false,
              bookedByUser: false,
              groupId: null,
            };
            setData({ ...data, timeSlots: newData });
          })
          .catch(err => {
            dispatchNotification(
              'Bokningsfel',
              // 'Bokningsfel',
              err.response && err.response.data && err.response.data.message,
              'danger',
            );
          });
      }
    } else if (eventProps.booked && props.user.role === userRolesConstants.admin) {
      if (confirm('Är du säker på att du vill avboka resursen?')) {
        props
          .unBookSlot(info.event.id)
          .then(() => {
            const newData = [...data.timeSlots];
            const index = newData.findIndex(data => data.id === info.event.id);
            newData[index] = {
              ...newData[index],
              booked: false,
              bookedByUser: false,
              groupId: '',
            };
            setData({ ...data, timeSlots: newData });
          })
          .catch(err => {
            dispatchNotification(
              'Bokningsfel',
              // 'Bokningsfel',
              err.response && err.response.data && err.response.data.message,
              'danger',
            );
          });
      }
    }
  };

  const getObjectParameter = key => {
    if (props.calendarList.length) {
      const index = props.calendarList.findIndex(item => parseInt(id, 10) === item.id);
      const obj = { ...props.calendarList[index] };

      return obj[key];
    }
  };

  const goBack = () => {
    history.push('/');
  };

  return (
    <div className="calendar-page">
      <div className="calendar-page__go-back" onClick={goBack}>
        {<LeftCircleOutlined />} Tillbaka till huvudmenyn
      </div>
      <div className="calendar-page__name">{getObjectParameter('name')}</div>
      <div className="calendar-page__description">{getObjectParameter('description')}</div>
      {/*<div>Announcement: {getObjectParameter('announcement')}</div>*/}
      <div className="calendar-component">
        <div className="calendar-component__explanation">
          <div>Tillgänglig</div>
          <div>Bokad</div>
          <div>Inte tillgänglig</div>
        </div>
        <FullCalendar
          weekNumberCalculation="ISO"
          header={{ left: 'prev,next today', center: '', right: 'title' }}
          buttonText={{ today: 'I dag' }}
          height="auto"
          columnHeaderFormat={{ weekday: 'short', day: 'numeric' }}
          allDaySlot={false}
          slotEventOverlap={false}
          plugins={[timeGridPlugin, interactionPlugin]}
          defaultView="timeGridWeek"
          locale={'sv'}
          minTime={data ? `${data.settings.minHour}:00` : '00:00'}
          maxTime={data ? `${data.settings.maxHour}:00` : '24:00'}
          eventClick={eventOnClick}
          events={events}
          datesRender={viewOnChange}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  calendarList: state.calendar.calendars,
});

const mapDispatchToProps = dispatch => ({
  getSingleCalendar: bindActionCreators(getSingleCalendar, dispatch),
  bookSlot: bindActionCreators(bookSlot, dispatch),
  unBookSlot: bindActionCreators(unBookSlot, dispatch),
  getCalendars: bindActionCreators(getCalendars, dispatch),
});

CalendarPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  getSingleCalendar: PropTypes.func,
  bookSlot: PropTypes.func,
  getCalendars: PropTypes.func,
  unBookSlot: PropTypes.func,
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
  calendarList: PropTypes.arrayOf(PropTypes.shape({})),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CalendarPage));
