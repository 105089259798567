// import axios from 'axios';

import { GET_USERS, EDIT_USER, CREATE_USER, DELETE_USER } from '../constants/actionTypes';
import api from '../utils/api';

export const getUsers = () => dispatch =>
  new Promise((resolve, reject) =>
    api.users
      .getUsers()
      .then(res => {
        dispatch({
          type: GET_USERS,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );

export const editUser = data => dispatch =>
  new Promise((resolve, reject) =>
    api.users
      .editUser(data)
      .then(res => {
        dispatch({
          type: EDIT_USER,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );

export const createUser = data => dispatch =>
  new Promise((resolve, reject) =>
    api.users
      .createUser(data)
      .then(res => {
        dispatch({
          type: CREATE_USER,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );

export const deleteUser = id => dispatch =>
  new Promise((resolve, reject) =>
    api.users
      .deleteUser(id)
      .then(res => {
        dispatch({
          type: DELETE_USER,
          payload: id,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );
