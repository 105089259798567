import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import navBarReducer from './reducers/navBarReducer';
import authReducer from './reducers/authReducer';
import calendarReducer from './reducers/calendarReducer';
import usersReducer from './reducers/usersReducer';
import invoicesReducer from './reducers/invoicesReducer';

export const rootReducer = combineReducers({
  navBar: navBarReducer,
  auth: authReducer,
  calendar: calendarReducer,
  users: usersReducer,
  invoices: invoicesReducer,
});

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
