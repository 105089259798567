import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Libraries
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { login, getUser } from '../../actions/authActions';

// Components
import { Input, Button } from 'antd';
import history from '../../utils/history';

import './loginPage.sass';

const LoginPage = props => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [error, setError] = useState({ username: null, password: null, general: null });
  const [submitLoading, setSubmitLoading] = useState(false);

  const { general: generalError, username: usernameError, password: passwordError } = error;

  const onChange = e => {
    const {
      target: { id, value },
    } = e;
    if (id === 'username') setUsername(value);
    else if (id === 'password') setPassword(value);
  };

  const clearComponent = () => {
    setUsername('');
    setPassword('');
    setIsButtonDisabled(true);
    setError({ username: null, password: null, general: null });
    setSubmitLoading(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitLoading(true);
    props.login &&
      props
        .login({ username, password })
        .then(() => {
          setSubmitLoading(false);
          props.getUser &&
            props.getUser().then(() => {
              history.push('/');
            });
        })
        .catch(err => {
          if (err && err.response && err.response.data)
            setError({ ...err.response.data.errors, general: err.response.data.message });
          setSubmitLoading(false);
        });
  };

  // useEffect(() => {
  //   const isCredentialsValid = () => username.length >= 3 && password.length >= 8;
  //   if (isCredentialsValid() && isButtonDisabled) setIsButtonDisabled(false);
  //   else if (!isCredentialsValid() && !isButtonDisabled) setIsButtonDisabled(true);
  // }, [username, password, isButtonDisabled]);

  useEffect(() => {
    return () => clearComponent();
  }, []);

  const forgotPassword = e => {
    e.preventDefault();
    history.push('/forgot-password');
  };

  return (
    <div className="login-page">
      <div className="login-page__wrapper">
        <h2>Brf Hammarby Sjö 1 bokningssystem</h2>
        {generalError && (
          <div className="login-page__general-error">
            {generalError && <div>{generalError}</div>}
            {usernameError && <div>{usernameError}</div>}
            {passwordError && <div>{passwordError}</div>}
          </div>
        )}
        <form onSubmit={handleSubmit} autoComplete="off">
          <Input value={username} id="username" onChange={onChange} error={error.username} autoFocus={true} />
          <Input value={password} id="password" type="password" onChange={onChange} error={error.password} />
          <div>
            <Button disabled={isButtonDisabled} htmlType="submit" loading={submitLoading}>
              Logga in
            </Button>
            <a onClick={forgotPassword} href="">
              Glömt ditt lösenord?
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  login: bindActionCreators(login, dispatch),
  getUser: bindActionCreators(getUser, dispatch),
});

LoginPage.propTypes = {
  login: PropTypes.func,
  getUser: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(LoginPage);
