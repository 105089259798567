import React from 'react';
import PropTypes from 'prop-types';

import { Result, Typography, Button } from 'antd';

const { Paragraph, Text } = Typography;

class ErrorBoundary extends React.Component {
  state = { error: null, errorInfo: null };

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  restartPage = () => location.reload();

  render() {
    const { error, errorInfo } = this.state;
    const errorBody = error ? error.toString() : '';
    const errorInfoBody = errorInfo ? errorInfo.componentStack : '';

    return this.state.error ? (
      <Result
        status="error"
        title={errorBody}
        subTitle="Please contact the Admin and give him the following information below"
        extra={
          <Button type="primary" onClick={this.restartPage}>
            Restart the Page
          </Button>
        }
      >
        <Paragraph>
          <Text
            strong
            style={{
              fontSize: 16,
              whiteSpace: 'pre-wrap',
            }}
          >
            {errorInfoBody}
          </Text>
        </Paragraph>
      </Result>
    ) : (
      this.props.children
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.shape({}),
};

export default ErrorBoundary;
