import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import LoginPage from './containers/LoginPage/loginPage';
import NonAuthRoute from './components/routes/nonAuthRoutes';
import routes, { NonAuthRoutes } from './constants/routes';
import userRoles from './constants/userRoles';
import ForgotPasswordPage from './containers/ForgotPasswordPage';
import ResetPasswordPage from './containers/ResetPasswordPage';
import { Switch, Redirect } from 'react-router-dom';

const UnauthenticatedApp = props => {
  const isNonAuthPath = Object.values(NonAuthRoutes).find(el => {
    return el === props.location.pathname;
  });
  return (
    <Switch>
      <NonAuthRoute exact path="/login" Component={LoginPage} requiredRoles={userRoles.all} />
      <NonAuthRoute path={routes.FORGOT_PASSWORD} Component={ForgotPasswordPage} requiredRoles={userRoles.all} />
      <NonAuthRoute path={routes.RESET_PASSWORD} Component={ResetPasswordPage} requiredRoles={userRoles.all} />
      <Redirect
        to={{
          pathname: isNonAuthPath ? props.location.pathname : NonAuthRoutes.login,
        }}
      />
    </Switch>
  );
};

UnauthenticatedApp.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default withRouter(UnauthenticatedApp);
