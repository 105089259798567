import React, { PureComponent, memo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { apiLogout } from '../../actions/authActions';

import { FileOutlined, UserOutlined, CalendarOutlined } from '@ant-design/icons';

import routesPath, { HOME, INVOICES, USERS } from '../../constants/routes';

import './navBarLeft.sass';

class NavBarLeft extends PureComponent {
  logout = () => {
    this.props.logout && this.props.logout();
  };

  render() {
    const { expanded, location } = this.props;
    return (
      <div className={`nav-bar-left ${expanded && '--expand'}`}>
        <div className="nav-bar-left__wrapper">
          <Link
            to={routesPath.HOME}
            className={`nav-bar-left__item ${location.pathname === routesPath.HOME && '--active'}`}
          >
            <span className="nav-bar-left__item_bar" />
            <CalendarOutlined />
            <div className="nav-bar-text">{HOME}</div>
          </Link>
          <Link
            to={routesPath.INVOICES}
            className={`nav-bar-left__item ${location.pathname === routesPath.INVOICES && '--active'}`}
          >
            <span className="nav-bar-left__item_bar" />
            <FileOutlined />
            <div className="nav-bar-text">{INVOICES}</div>
          </Link>
          <Link
            to={routesPath.USERS}
            className={`nav-bar-left__item ${location.pathname === routesPath.USERS && '--active'}`}
          >
            <span className="nav-bar-left__item_bar" />
            <UserOutlined />
            <div className="nav-bar-text">{USERS}</div>
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  expanded: state.navBar.expanded,
});

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(apiLogout, dispatch),
});

NavBarLeft.propTypes = {
  expanded: PropTypes.bool,
  logout: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBarLeft)));
