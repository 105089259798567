import { TOGGLE_BAR, SET_GLOBAL_LOADING } from '../constants/actionTypes';

export const toggleBar = () => ({
  type: TOGGLE_BAR,
});

export const setGlobalLoading = loadingState => ({
  type: SET_GLOBAL_LOADING,
  payload: loadingState,
});
