import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Libraries
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { resetPassword } from '../../actions/authActions';

// Components
import { Input, Button } from 'antd';
import history from '../../utils/history';

import './resetPasswordPage.sass';
import dispatchNotification from '../../components/notification';

const ResetPasswordPage = ({ resetPassword }) => {
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState({ email: null, newPassword: null, repeatPassword: null, general: null });

  const { general: generalError, newPassword: newPasswordError, password: repeatPasswordError } = error;

  const onChange = e => {
    const {
      target: { id, value },
    } = e;
    if (id === 'newPassword') setNewPassword(value);
    else if (id === 'repeatPassword') setRepeatPassword(value);
  };

  const clearComponent = () => {
    setNewPassword('');
    setRepeatPassword('');
    setIsButtonDisabled(false);
    setError({ newPassword: null, repeatPassword: null, general: null });
    setSubmitLoading(false);
  };

  useEffect(() => {
    return () => clearComponent();
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    const hash = window.location.search.split('?')[1];
    if (hash !== '' && hash !== undefined) {
      if (newPassword === repeatPassword) {
        resetPassword(newPassword, hash).then(() => {
          dispatchNotification('Success', 'Lösenordet återställdes, vänligen logga in', 'success');
          history.push('/login');
        });
      }
    } else dispatchNotification('Error', 'Invalid hash', 'danger');
  };

  const signIn = e => {
    e.preventDefault();
    history.push('/login');
  };

  return (
    <div className="reset-page">
      <div className="reset-page__wrapper">
        <h2>Återställ lösenord</h2>
        {generalError && (
          <div className="reset-page__general-error">
            {generalError && <div>{generalError}</div>}
            {newPasswordError && <div>{newPasswordError}</div>}
            {repeatPasswordError && <div>{repeatPasswordError}</div>}
          </div>
        )}
        <form onSubmit={handleSubmit} autoComplete="off">
          <Input value={newPassword} id="newPassword" type="password" onChange={onChange} error={error.username} />
          <Input
            value={repeatPassword}
            id="repeatPassword"
            type="password"
            onChange={onChange}
            error={error.password}
          />
          <div>
            <Button disabled={isButtonDisabled} htmlType="submit" loading={submitLoading}>
              Återställ
            </Button>
            <a onClick={signIn} href="">
              Logga in
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  resetPassword: bindActionCreators(resetPassword, dispatch),
});

ResetPasswordPage.propTypes = {
  resetPassword: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(ResetPasswordPage);
