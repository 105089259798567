import React from 'react';
import PropTypes from 'prop-types';

import './loader.sass';

const Loader = ({ loading = false, className = '' }) => {
  return (
    <div className={`loader${className ? ` ${className}` : ''}`}>
      <div className={`loader__lds-ellipsis${loading ? ' loader__lds-ellipsis--loading' : ''}`}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export default Loader;
