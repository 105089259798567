import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import history from './utils/history';
import { store } from './store';

import Routes from './routes';
import ErrorBoundary from './components/errorBoundary';
import initApiConfig from './utils/apiConfig';

import 'antd/dist/antd.css';
import './index.sass';

initApiConfig();

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <Router history={history}>
        <Routes />
      </Router>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
);
