import axios from 'axios';
// import humps from 'humps';

import dispatchNotification from '../components/notification';
import { store } from '../store';
import { logout } from '../actions/authActions';

export const URL = 'https://hammarbysjo1-calendar.westmere.se/api';
// export const URL = 'http://local.api.calendar.westmere.se/api';
axios.defaults.baseURL = URL;
// axios.defaults.withCredentials = true;

// Bearer implementation
if (localStorage.getItem('token')) {
  const token = localStorage.getItem('token');
  const bearer = token && JSON.parse(token) ? JSON.parse(token) : '';
  axios.defaults.headers.common.Authorization = `Bearer ${bearer}`;
} else {
  axios.defaults.headers.common.Authorization = 'Bearer ';
}

axios.interceptors.request.use(config => config);

axios.interceptors.response.use(
  response => response,
  error => {
    const { response: { status } = { status: 0 } } = error;
    switch (status) {
      case 401: {
        if (
          error.response.statusText === 'Unauthenticated' ||
          error.response.statusText === 'No JWT token found!' ||
          error.response.statusText === 'Unauthorized'
        ) {
          dispatchNotification('Error 401', error.response.data.message, 'danger');
          store.dispatch(logout());
        } else {
          store.dispatch(logout());
          dispatchNotification('Error 401', error.response.data.message, 'danger');
        }
        break;
      }
      case 400: {
        dispatchNotification('Error 400', `${error.message}. ${error.response.data.message}`, 'danger');
        break;
      }
      case 429: {
        dispatchNotification('Error 429', `${error.message}. ${error.response.data.message}`, 'danger');
        break;
      }
      case 500: {
        store.dispatch(logout());
        break;
      }
      default:
        break;
    }

    return Promise.reject(error);
  },
);

export default () => null;
