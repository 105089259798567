import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { NonAuthRoutes } from '../../constants/routes';

const AuthRoute = ({ user, Component, path, exact = false, requiredRoles }) => {
  const isLogged = !!localStorage.getItem('token');
  const userRole = user && user.role ? user.role : '';
  const userHasRequiredRole = requiredRoles.includes(userRole);
  const message = userHasRequiredRole ? 'Please log in to view this page' : 'You cant be here!';

  return (
    <Route
      exact={exact}
      path={path}
      render={props =>
        isLogged && userHasRequiredRole ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: userHasRequiredRole ? NonAuthRoutes.login : NonAuthRoutes.unauthorized,
              state: { message, requestPath: path },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

AuthRoute.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  path: PropTypes.string,
  exact: PropTypes.bool,
  requiredRoles: PropTypes.array,
};

export default connect(mapStateToProps)(AuthRoute);
