// import axios from 'axios';

import {
  GET_CALENDARS,
  EDIT_CALENDARS,
  CREATE_CALENDARS,
  GET_SINGLE_CALENDAR,
  BOOK_SLOT,
  UN_BOOK_SLOT,
  DELETE_CALENDAR,
} from '../constants/actionTypes';
import api from '../utils/api';

export const getCalendars = () => dispatch =>
  new Promise((resolve, reject) =>
    api.calendar
      .getCalendars()
      .then(res => {
        dispatch({
          type: GET_CALENDARS,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );

export const editCalendar = data => dispatch =>
  new Promise((resolve, reject) =>
    api.calendar
      .editCalendar(data)
      .then(res => {
        dispatch({
          type: EDIT_CALENDARS,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );

export const createCalendar = data => dispatch =>
  new Promise((resolve, reject) =>
    api.calendar
      .createCalendar(data)
      .then(res => {
        dispatch({
          type: CREATE_CALENDARS,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );

export const getSingleCalendar = (id, start, end) => dispatch =>
  new Promise((resolve, reject) =>
    api.calendar
      .getSingleCalendar(id, start, end)
      .then(res => {
        dispatch({
          type: GET_SINGLE_CALENDAR,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );

export const bookSlot = data => dispatch =>
  new Promise((resolve, reject) =>
    api.calendar
      .bookSlot(data)
      .then(res => {
        dispatch({
          type: BOOK_SLOT,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );

export const unBookSlot = id => dispatch =>
  new Promise((resolve, reject) =>
    api.calendar
      .unBookSlot(id)
      .then(res => {
        dispatch({
          type: UN_BOOK_SLOT,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );

export const deleteCalendar = id => dispatch =>
  new Promise((resolve, reject) =>
    api.calendar
      .deleteCalendar(id)
      .then(res => {
        dispatch({
          type: DELETE_CALENDAR,
          payload: id,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );
