import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, TimePicker, Form, Input, InputNumber, Modal, Switch, Select } from 'antd';
import moment from 'moment';
import { PlusCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const ModalCalendar = ({
  data,
  showModal,
  submitDataLoading,
  handleModalClose,
  handleModalSave,
  isModalEdit,
  handleModalCreate,
}) => {
  const [modalData, setModalData] = useState(data);

  const timeOnChange = (e, index) => {
    const newSlots = [...modalData.slots];
    if (e) {
      newSlots[index].startTime = e.format('HH:mm:ss');
      setModalData({ ...modalData, slots: newSlots });
    } else {
      newSlots[index].startTime = '';
      setModalData({ ...modalData, slots: newSlots });
    }
  };

  const formOnChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    if (name !== 'date' && name !== 'price') setModalData({ ...modalData, [name]: value });
    else if (name === 'price') setModalData({ ...modalData, [name]: value.split('SEK')[1] });
  };

  const onPressEnter = (value, name, index) => {
    const newSlots = [...modalData.slots];

    if (name === 'date') {
      newSlots[index].hours = value;

      setModalData({ ...modalData, slots: newSlots });
    } else setModalData({ ...modalData, [name]: value });
  };

  const addSlot = () => {
    const newSlots = [...modalData.slots];

    newSlots.push({ startTime: null, hours: null });
    setModalData({ ...modalData, slots: newSlots });
  };

  const deleteSlot = index => {
    const newSlots = [...modalData.slots];

    newSlots.splice(index, 1);
    setModalData({ ...modalData, slots: newSlots });
  };

  const readerOnChange = reader => setModalData({ ...modalData, reader });

  return (
    <Modal
      width={700}
      visible={showModal}
      title="Titel"
      style={{ top: 20 }}
      onCancel={handleModalClose}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={handleModalClose}>
          Stäng
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={submitDataLoading}
          onClick={() => (isModalEdit ? handleModalSave(modalData) : handleModalCreate(modalData))}
        >
          Spara
        </Button>,
      ]}
    >
      <Form onChange={formOnChange} labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} layout="horizontal">
        <Form.Item labelCol={{ span: 6 }} label="Name">
          <Input placeholder="Name" name="name" value={modalData.name} />
        </Form.Item>
        <Form.Item labelCol={{ span: 6 }} label="Description">
          <Input.TextArea placeholder="Description" name="description" value={modalData.description} />
        </Form.Item>
        <Form.Item labelCol={{ span: 6 }} label="Tidig åtkomst (min.)">
          <InputNumber
            onChange={e => onPressEnter(e, 'earlyAccess')}
            name="earlyAccess"
            // Default value for InputValue Component
            value={modalData && modalData.earlyAccess ? modalData.earlyAccess : 0}
            min={0}
          />
        </Form.Item>
        <Form.Item labelCol={{ span: 6 }} label="Daglig maxgräns">
          <InputNumber onChange={e => onPressEnter(e, 'slotCap')} name="slotCap" value={modalData.slotCap} min={0} />
        </Form.Item>
        <Form.Item labelCol={{ span: 6 }} label="Tidsintervall">
          <div>
            <div onClick={addSlot} className="home-page-calendar__create">
              <PlusCircleOutlined />
              <span>Lägg till ny tidsintervall</span>
            </div>
            {modalData.slots.map((item, index) => {
              return (
                <div key={`slots-slot-${index}`}>
                  <TimePicker
                    minuteStep={5}
                    format="HH:mm"
                    onChange={e => timeOnChange(e, index)}
                    name="start"
                    value={item.startTime ? moment(item.startTime, 'HH:mm:ss') : null}
                  />
                  <InputNumber onChange={e => onPressEnter(e, 'date', index)} name="date" value={item.hours} min={0} />
                  <CloseCircleOutlined onClick={() => deleteSlot(index)} className="home-page-calendar__delete-slot" />
                </div>
              );
            })}
          </div>
        </Form.Item>
        <Form.Item labelCol={{ span: 6 }} label="Pris">
          <InputNumber
            onChange={e => onPressEnter(e, 'price')}
            name="price"
            value={modalData.price}
            style={{ width: 100 }}
            formatter={v => {
              if (!isNaN(v)) return `SEK ${v}`;
              const split = v.split('SEK');
              return split.length > 1 ? `SEK ${split[1]}` : `SEK ${v}`;
            }}
            // formatter={value => `Sk ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            // parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        <Form.Item labelCol={{ span: 6 }} label="Läsare">
          <Select placeholder="Läsare" value={modalData.reader} onChange={readerOnChange}>
            <Select.Option value="17">Bastu: SK13C-2-BASTU (17)</Select.Option>
            <Select.Option value="18">Hobby Lokal: SK13C-2-Hobb (18)</Select.Option>
            <Select.Option value="47">Festlokal: SK17A-1-FST (47)</Select.Option>
            <Select.Option value="50">Gästlägenhet: SK17A-2-OV (50)</Select.Option>
            <Select.Option value="99">Grovtvättmaskin: SK17A-1-GROV (99)</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item labelCol={{ span: 6 }} label="Faktureringskonto-ID">
          <Input name="billingAccount" value={modalData.billingAccount} />
        </Form.Item>
        <Form.Item labelCol={{ span: 6 }} label="Avbokningsperiodgräns (tim)">
          <InputNumber
            onChange={e => onPressEnter(e, 'cancellationPeriodLimit')}
            name="cancellationPeriodLimit"
            value={modalData.cancellationPeriodLimit}
            min={0}
          />
        </Form.Item>
        <Form.Item labelCol={{ span: 6 }} label="Aktiv">
          <Switch onChange={e => onPressEnter(e, 'active')} name="active" checked={modalData.active} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalCalendar.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    announcement: PropTypes.string,
    earlyAccess: PropTypes.number,
    slotCap: PropTypes.number,
    slots: PropTypes.arrayOf(
      PropTypes.shape({
        startTime: PropTypes.string,
        hours: PropTypes.number,
      }),
    ),
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    reader: PropTypes.string,
    billingAccount: PropTypes.string,
    cancellationPeriodLimit: PropTypes.number,
    active: PropTypes.bool,
  }),
  showModal: PropTypes.bool,
  submitDataLoading: PropTypes.bool,
  handleModalSave: PropTypes.func,
  handleModalClose: PropTypes.func,
  formOnChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  dateStartOnChange: PropTypes.func,
  timeStartOnChange: PropTypes.func,
  addSlot: PropTypes.func,
  deleteSlot: PropTypes.func,
  handleModalCreate: PropTypes.func,
  isModalEdit: PropTypes.bool,
};

export default ModalCalendar;
