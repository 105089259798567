import axios from 'axios';

export default {
  auth: {
    login: data => axios.post('/login_check', data),
    // logout: () => axios.get('/logout'),
    logout: () => null,
    getUser: () => axios.get('user/info'),
    getResetLink: username => axios.post('/user/reset', { username }),
    resetPassword: (password, token) => axios.post(`/user/reset/${token}`, { password }),
  },
  calendar: {
    getCalendars: () => axios.get('/schedule/'),
    deleteCalendar: id => axios.delete(`/schedule/${id}`),
    editCalendar: data => axios.put(`/schedule/${data.id}`, data),
    createCalendar: data => axios.post(`/schedule/`, data),
    getSingleCalendar: (id, start, end) => axios.get(`/booking/?schedule=${id}&start=${start}&end=${end}`),
    bookSlot: data => axios.post('booking/', data),
    unBookSlot: id => axios.delete(`booking/${id}`),
  },
  users: {
    getUsers: () => axios.get('/user/'),
    createUser: data => axios.post('/user/', data),
    editUser: data => axios.put(`/user/${data.id}`, data),
    deleteUser: id => axios.delete(`/user/${id}`),
  },
  invoices: {
    getInvoices: () => axios.get('/invoice/'),
    downloadInvoice: fileName => axios.get(`/invoice/download/${fileName}`),
  },
};
