import { CREATE_USER, EDIT_USER, GET_USERS, DELETE_USER } from '../constants/actionTypes';

export default function usersReducer(state = null, action) {
  switch (action.type) {
    case GET_USERS:
      return action.payload;
    case EDIT_USER: {
      const newList = [...state];
      const index = newList.findIndex(item => item.id === action.payload.id);
      newList[index] = action.payload;
      return newList;
    }
    case CREATE_USER:
      return [...state, { ...action.payload }];
    case DELETE_USER: {
      const newList = [...state];
      const index = newList.findIndex(item => item.id === action.payload);
      newList.splice(index, 1);
      return newList;
    }
    default:
      return state;
  }
}
