export const userRolesConstants = {
  admin: 'ROLE_ADMIN',
  user: 'ROLE_USER',
};

const userRoles = {
  admins: [String(userRolesConstants.admin)],
  user: [String(userRolesConstants.user)],
  all: [userRolesConstants.admin, userRolesConstants.user],
};

export default userRoles;
