import axios from 'axios';

import { LOGIN, LOGOUT, SET_LOGIN_FROM_SESSION, GET_USER } from '../constants/actionTypes';
import api from '../utils/api';

export const login = data => dispatch =>
  new Promise((resolve, reject) =>
    api.auth
      .login(data)
      .then(res => {
        localStorage.setItem('token', JSON.stringify(res.data.token));
        const bearer = res && res.data.token ? res.data.token : '';
        axios.defaults.headers.common.Authorization = `Bearer ${bearer}`;
        dispatch({
          type: LOGIN,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );

export const logout = () => {
  window.localStorage.removeItem('token');
  return {
    type: LOGOUT,
  };
};

export const apiLogout = () => {
  window.localStorage.removeItem('token');
  api.auth.logout();
  return {
    type: LOGOUT,
  };
};

export const setLoginFromSession = () => {
  const token = window.localStorage.getItem('token');
  if (token && token !== 'undefined' && token !== 'null') {
    return {
      type: SET_LOGIN_FROM_SESSION,
      payload: JSON.parse(token),
    };
  } else {
    return {
      type: LOGOUT,
    };
  }
};

export const getUser = () => dispatch =>
  new Promise((resolve, reject) =>
    api.auth
      .getUser()
      .then(res => {
        dispatch({
          type: GET_USER,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );

export const getResetLink = username => dispatch =>
  new Promise((resolve, reject) =>
    api.auth
      .getResetLink(username)
      .then(res => {
        dispatch({
          type: 'RESET_LINK',
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );

export const resetPassword = (password, token) => dispatch =>
  new Promise((resolve, reject) =>
    api.auth
      .resetPassword(password, token)
      .then(res => {
        dispatch({
          type: 'RESET_PASSWORD',
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );
