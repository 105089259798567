import React from 'react';
import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';

const NotFoundPage = () => (
  <Result
    status="404"
    title="404"
    subTitle="Tyvärr finns inte denna sida."
    extra={
      <Link to="/">
        <Button type="primary">Back Home</Button>
      </Link>
    }
  />
);
export default NotFoundPage;
