import { LOGIN, LOGOUT, SET_LOGIN_FROM_SESSION, GET_USER } from '../constants/actionTypes';

const initialState = {
  login: null,
  user: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        login: action.payload,
      };
    case SET_LOGIN_FROM_SESSION:
      return {
        ...state,
        login: action.payload,
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case LOGOUT:
      return {
        ...initialState,
        login: false,
      };
    default:
      return state;
  }
}
