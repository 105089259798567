import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';

import routes from './constants/routes';

import App from './App';
import Loader from './components/loader';

import HomePage from './containers/HomePage';
import CalendarPage from './containers/CalendarPage';
import InvoicesPage from './containers/InvoicesPage';
import UsersPage from './containers/UsersPage';
import NotFoundPage from './containers/NotFoundPage';
import NonAuthorizedPage from './containers/NonAuthorizedPage';

import NavBarLeft from './components/navBarLeft';

import AuthRoute from './components/routes/authRoutes';

import userRoles, { userRolesConstants } from './constants/userRoles';

import './app.sass';
import PropTypes from 'prop-types';

// Lazy loading implementation
// const HomePage = React.lazy(() => import('./containers/HomePage'));
// const NotFoundPage = React.lazy(() => import('./containers/NotFoundPage'));
// const NonAuthorizedPage = React.lazy(() => import('./containers/NonAuthorizedPage'));

const Routes = ({ user }) => {
  return (
    <App>
      <div className="app__wrapper">
        {user && user.role === userRolesConstants.admin && <NavBarLeft />}
        <div className="route-container">
          <Suspense
            fallback={
              <div className="suspense-loading-wrapper">
                <Loader loading />
              </div>
            }
          >
            <Switch>
              <AuthRoute exact path={routes.HOME} Component={HomePage} requiredRoles={userRoles.all} />
              <AuthRoute path={routes.CALENDAR} Component={CalendarPage} requiredRoles={userRoles.all} />
              <AuthRoute path={routes.INVOICES} Component={InvoicesPage} requiredRoles={userRoles.admins} />
              <AuthRoute path={routes.USERS} Component={UsersPage} requiredRoles={userRoles.admins} />
              <Route path={routes.UNAUTHORIZED} component={NonAuthorizedPage} />
              <Route component={NotFoundPage} />
            </Switch>
          </Suspense>
        </div>
      </div>
    </App>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

Routes.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
};

export default withRouter(connect(mapStateToProps)(Routes));
